import React from 'react';
import { MapPin } from 'lucide-react';

const Sitemap = () => {
  const sitemapData = [
    {
      title: 'Main Pages',
      urls: [
        { path: '/', name: 'Home' },
        { path: '/booking', name: 'Booking' },
        { path: '/about', name: 'About Us' },
        { path: '/service', name: 'Services' },
        { path: '/contact', name: 'Contact' },
      ],
    },
    {
      title: 'Service Areas',
      urls: [
        { path: '/service-area-brisbane', name: 'Brisbane Service Area' },
        { path: '/service-area-goldcoast', name: 'Gold Coast Service Area' },
        { path: '/service-area-sunshinestate', name: 'Sunshine State Service Area' },
      ],
    },
    {
      title: 'Additional Information',
      urls: [{ path: '/bccPolicies', name: 'Policies' }],
    },
  ];

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-8 text-center">Site Map</h1>

      {sitemapData.map((section, index) => (
        <div key={index} className="mb-6 p-4 border rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <MapPin className="mr-2 h-5 w-5" />
            {section.title}
          </h2>
          <ul className="space-y-3">
            {section.urls.map((url, urlIndex) => (
              <li key={urlIndex} className="hover:bg-gray-50 p-2 rounded-lg transition-colors">
                <a href={url.path} className="text-blue-600 hover:text-blue-800">
                  {url.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Sitemap;
