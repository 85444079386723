import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import HeroAbout from './hero-about';
import GroundTransport from '../general/ground-transport';
import WhyChooseUs from '../general/why-choose-us';
import { Helmet } from "react-helmet-async";


function MainAbout() {
  return (
    <div className="content-wrapper">
      <Helmet>
        <link rel="canonical" href="https://www.businessclasschauffeur.com.au/about" />
      </Helmet>
      <Menu /> 
      <HeroAbout />
      <GroundTransport />
      <WhyChooseUs />
      <Footer />
    </div>
  );
}

export default MainAbout;