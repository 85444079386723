import React from 'react';

import Footer from '../common/footer';
import Sitemap from './Sitemap';
import HeroSitemap from './hero-sitemap';
import Menu from '../common/menu';

function MainSitemap() {
    return (
        <div className="content-wrapper">
            <Menu />            
            <HeroSitemap />
            <Sitemap />
            <Footer />
        </div>
    );
}

export default MainSitemap; 