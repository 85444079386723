import React from 'react';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import HeroGoldCoast from './hero-goldcoast';
import ContentGoldCoast from './content-goldcoast';
import { Helmet } from "react-helmet-async";

function MainGoldCoast() {
    return (
        <div className="content-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.businessclasschauffeur.com.au/service-area-goldcoast" />
                <title>Limousine Service Gold Coast | Airport Transfers | Luxury Car Hire | Gold Coast Chauffeur Service</title>
                <meta name="description" content="Experience private airport transfers, corporate car hire, and elegant wedding limousine services in Gold Coast. Luxury, comfort, and professionalism guaranteed." />
                <meta name="keywords" content="Limousine service Gold Coast, Gold Coast airport transfers, Luxury car hire Gold Coast, Gold Coast chauffeur service, Private airport transfers Gold Coast, Corporate car hire Gold Coast, Wedding limousine Gold Coast" />
            </Helmet>
            <Menu />
            <HeroGoldCoast />
            <ContentGoldCoast />
            <Footer />
        </div>
    );
}

export default MainGoldCoast; 