import {React} from 'react';
import HeroSection from '../feature/hero/hero-section';
import Menu from './menu';
import ServicesSection from '../feature/hero/services-section';
import SectionFeatures from '../feature/hero/section-features';
import AppDownload from '../feature/hero/app-download';
import Footer from './footer';
import { Helmet } from "react-helmet-async";

function Home() {

  /*
  const [loadFlag, setLoadFlag] = useState(false);

  useEffect(() => {
    // Helper function to load a script
    const loadScript = (src) => {
      console.log('loadFlag value ---> ' + loadFlag);
      if(loadFlag){
          return;
      }
      console.log('Script file ---> ' + src);
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
        return script;
    };

    // Load multiple scripts and manage them
      let s = loadScript('./assets/js/plugins.js');
      let s2 = loadScript('./assets/js/theme.js');
      setLoadFlag(true);
     
      return () => {
           document.body.removeChild(s);
           document.body.removeChild(s2);
      };

  }, []);
  */
  return (
    <div className="content-wrapper">
      <Helmet>
        <link rel="canonical" href="https://www.businessclasschauffeur.com.au/" />
        <title>Limousine Services Australia | Luxury Airport Transfers | Corporate Car Services | Private Chauffeur Driven Cars Australia</title>
        <meta name="description" content="Experience premium limousine hire in Australia for weddings, events, and airport transfers. Professional chauffeurs ensure luxury, comfort, and reliability." />
        <meta name="keywords" content="Limousine services Australia, Luxury airport transfers Australia, Corporate car services Australia, Private chauffeur driven cars Australia, Premium limousine hire Australia, Wedding transportation Australia, Event chauffeur service Australia, Airport limousine hire Australia" />
      </Helmet>
       <Menu /> 
      <HeroSection />
     {/* <StatsSection /> */}
      <ServicesSection />
     {/* <StateSection /> */}
      <SectionFeatures />
      <AppDownload />
      {/* <AppContact /> */}
      <Footer />
    </div>
  );
}

export default Home;