import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import NotFound from './NotFound';


function MainNotFound() {
    return (
        <div className="content-wrapper">
            <Menu />
            <NotFound />
            <Footer />
        </div>
    );
}

export default MainNotFound;