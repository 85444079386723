import React from 'react';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import HeroSunshineState from './hero-sunshinestate';
import ContentSunshineState from './content-sunshinestate';
import { Helmet } from "react-helmet-async";

function MainSunshineState() {
    return (
        <div className="content-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.businessclasschauffeur.com.au/service-area-sunshinestate" />
                <title>Limousine Service Sunshine Coast | Airport Transfers | Chauffeur Service Sunshine Coast | Wedding Car Hire Sunshine Coast</title>
                <meta name="description" content="Discover private car hire, corporate car service, and event transportation in Sunshine Coast. Enjoy luxury rides with professional and reliable chauffeurs." />
                <meta name="keywords" content="Limousine service Sunshine Coast, Sunshine Coast airport transfers, Chauffeur service Sunshine Coast, Private car hire Sunshine Coast, Wedding car hire Sunshine Coast, Corporate car service Sunshine Coast, Event transportation Sunshine Coast, Sunshine Coast luxury rides" />
            </Helmet>
            <Menu />
            <HeroSunshineState />
            <ContentSunshineState />
            <Footer />
        </div>
    );
}

export default MainSunshineState; 