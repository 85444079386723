import { useEffect, useState, useContext } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { BookingContext } from "../../../contexts/bookingContext";
import { createPaymentIntent, getPaymentConfiguration } from "../../../services/payment-service";
import { saveBookingDetails } from "../../../db/bookingDAO";

function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  let { bookingDetails, setBookingDetails } = useContext(BookingContext);

  useEffect(() => {

    const configureAndCreatePaymentIntent = async () => {
      if (bookingDetails.pageDisplay.currentTab === 4) {

        let bookingId = bookingDetails.bookingId !== 0 ? bookingDetails.bookingId : await saveBookingDetails(bookingDetails);
        if (bookingId === -1) {

          console.log("Error in creating the booking, Please contact the support team.");
          return;
        } else {
          bookingDetails.bookingId = bookingId;
          setBookingDetails((prevDetails) => ({
            ...prevDetails,
            bookingId: bookingId,
            isBookingLogged: true
          }));

          const publishableKey = await getPaymentConfiguration();
          setStripePromise(loadStripe(publishableKey));
  
          var { clientSecret, paymentIntentId } = await createPaymentIntent(bookingDetails.bookingFee, bookingId);
          setClientSecret(clientSecret);
          bookingDetails.paymentDetails.paymentIntentId = paymentIntentId;
  
        }
      }
    }
    configureAndCreatePaymentIntent();
  }, [bookingDetails.pageDisplay.currentTab]);


  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

export default Payment;