import { React, useEffect } from 'react';
import Menu from '../../common/menu';
import HeroBrisbane from './hero-brisbane';
import Footer from '../../common/footer';
import ContentBrisbane from './content-brisbane';
import { Helmet } from "react-helmet-async";

function MainBrisbane() {
    return (
        <div className="content-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.businessclasschauffeur.com.au/service-area-brisbane" />
                <title>Limousine Service Brisbane | Airport Transfers | Luxury Car Hire Brisbane | Corporate Limousine Brisbane</title>
                <meta name="description" content="Book premium Brisbane airport limousine service with professional chauffeurs. Enjoy luxury city transfers and reliable transportation across Brisbane." />
                <meta name="keywords" content="Limousine service Brisbane, Brisbane airport transfers, Luxury car hire Brisbane, Brisbane airport limousine service, Corporate limousine Brisbane, Premium chauffeur service Brisbane, Brisbane city transfers" />
            </Helmet>
            <Menu />
            <HeroBrisbane />
            <ContentBrisbane />
            <Footer />
        </div>
    );
}

export default MainBrisbane; 