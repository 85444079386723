import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import Home from './components/common/home';
import ScrollToTop from './components/common/ScrollToTop';
import MainContact from './components/contact/main-contact';

import MainAirportTransfers from './components/airport-transfers/main-airport-transfers';
//import MainLocation from './components/location/main-location';
import MainServices from './components/services/main-services';
import MainFleet from './components/fleet/main-fleet';
import MainAbout from './components/about/main-about';
import MainFAQs from './components/faqs/main-faqs';
import MainJoinFleet from './components/join-fleet/main-join-fleet';
import MainAccount from './components/account/main-account';
import MainReview from './components/review/main-review';
import MainChauffeur from './components/join-fleet/chauffeur/main-chauffeur';
import MainBooking from './components/feature/booking/main-booking';
import Completion from './components/feature/booking/Completion';
import MainManageBooking from './components/manage-booking/main-manage-booking';
import MainAccountSignup from './components/account/main-account-signup';
import MainAccountSignupSuccess from './components/account/main-account-signup-success';
import MainTermsAndConditions from './components/terms-and-conditions/main-terms-and-conditions';
import MainPrivacyPolicy from './components/privacy-policy/main-privacy-policy';
import MainCancellationPolicy from './components/privacy-policy/cancellation-policy/main-cancellation-policy';
import MainChildSafetyPolicy from './components/privacy-policy/child-safety-policy/main-child-safety-policy';
import MainFatigueManagementPolicy from './components/privacy-policy/fatigue-management-policy/main-fatigue-management-policy';
import MainSocialResponsibilityPolicy from './components/privacy-policy/social-responsibility-policy/main-social-responsibility-policy';
import MainDataRetentionAndPrivacyPolicy from './components/privacy-policy/data-retention-and-privacy-policy/main-data-retention-and-privacy-policy';
import MainBrisbane from './components/service-areas/brisbane/main-brisbane';
import MainGoldCoast from './components/service-areas/goldcoast/main-goldcoast';
import MainSunshineState from './components/service-areas/sunshinestate/main-sunshinestate';
import MainServiceAreas from './components/service-areas/main-service-areas';
import MainNotFound from './components/not-found/main-not-found';
import MainSitemap from './components/sitemap/main-sitemap';


function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<MainContact />} />
          <Route path="/transfers" element={<MainAirportTransfers />} />
          {/*<Route path="/location" element={<MainLocation />} /> */}
          <Route path="/service-areas" element={<MainServiceAreas />} />
          <Route path="/service" element={<MainServices />} />
          <Route path="/service-area-brisbane" element={<MainBrisbane />} />
          <Route path="/service-area-goldcoast" element={<MainGoldCoast />} />
          <Route path="/service-area-sunshinestate" element={<MainSunshineState />} />
          <Route path="/fleet" element={<MainFleet />} />
          <Route path="/about" element={<MainAbout />} />
          <Route path="/faqs" element={<MainFAQs />} />
          <Route path="/joinFleet" element={<MainJoinFleet />} />
          <Route path="/signin" element={<MainAccount />} />
          <Route path="/signup" element={<MainAccountSignup />} />
          <Route path="/signupSuccess" element={<MainAccountSignupSuccess />} />
          <Route path="/reviews" element={<MainReview />} />
          <Route path="/chauffeur" element={<MainChauffeur />} />
          <Route path="/booking" element={<MainBooking />} />
          <Route path="/manage-booking" element={<MainManageBooking />} />
          <Route path="/termsAndConditions" element={<MainTermsAndConditions />} />
          <Route path="/bccPolicies" element={<MainPrivacyPolicy />} />
          <Route path="/cancellationPolicy" element={<MainCancellationPolicy />} />
          <Route path="/childSafetyPolicy" element={<MainChildSafetyPolicy />} />
          <Route path="/fatigueManagementPolicy" element={<MainFatigueManagementPolicy />} />
          <Route path="/socialResponsibilityPolicy" element={<MainSocialResponsibilityPolicy />} />
          <Route path="/dataRetentionAndPrivacyPolicy" element={<MainDataRetentionAndPrivacyPolicy />} />
          <Route path="/completion" element={<Completion />} />
          <Route path="/sitemap" element={<MainSitemap />} />

          {/* 404 Page - This should be the last route */}
          <Route path="*" element={<MainNotFound />} />
        </Routes>
      </Router>
      </HelmetProvider>
  );
}

export default App;