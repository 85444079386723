import React from 'react';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import BookingForm from './bookingForm';
import { BookingProvider } from '../../../contexts/bookingContext';
import { Helmet } from "react-helmet-async";

function MainBooking() {
    return (
        <div className="content-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.businessclasschauffeur.com.au/booking" />
            </Helmet>
            <Menu />
            <BookingProvider>
                <BookingForm />
            </BookingProvider>
            <Footer />
        </div>
    );
}

export default MainBooking;