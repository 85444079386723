import React, { useState, useEffect, useContext } from 'react';

import { getVehicleCategories } from '../../../db/vehicleDAO';
import { loadExternalScripts, removeExternalScripts } from '../../common/scripts';
import { BookingContext } from '../../../contexts/bookingContext';
import { calculateFare } from './utils/fareUtils';
import { useAuth } from '../../../contexts/authContext';
import { getUserById } from '../../../db/userDAO';

function FleetRange() {

    let { bookingDetails, setBookingDetails, bookingErrors, setBookingErrors } = useContext(BookingContext);
    const [fleetData, setFleetData] = useState([]);
    const { currentUser } = useAuth();
    

    // Reset all radio buttons to unchecked
    const resetSelection = () => {
        setBookingErrors({});
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            bookingFee: 0,
            selectedVehicleId: null,
            vehicleSelection: {
                id: '',
                type: '',
                name: ''
            }
        }));
    };

    useEffect(() => {
        const getFleetData = async () => {
            try {
                resetSelection();
                if (fleetData != null && fleetData.length > 0) {

                // TODO: code to be removed added this code apple pay testing and setting the amount to $5 for the user codecrafters4fun@gmail.com
                    let emailId = null;
                    if (currentUser) {
                        try {
                            const userData = await getUserById(currentUser.uid);
                            emailId = userData.email;
                        } catch (error) {
                            console.error("Error fetching user data:", error);
                        }
                    }
                    //

                    const updatedFleetData = fleetData.map((category) => {
                        let amount = calculateFare(category, bookingDetails);

                        // TODO: code to be removed
                        if(emailId && emailId === 'codecrafters4fun@gmail.com'){
                            amount = 5;
                        }

                        return { ...category, bookingFee: amount };
                    });

                    setFleetData(updatedFleetData);
                } else {
                    const data = await getVehicleCategories();
                    setFleetData(data);
                }
                loadExternalScripts();
            } catch (error) {
                console.log('Error', error.message);
            } finally {
            }
        };

        getFleetData();

        return () => {
            removeExternalScripts(); // Cleanup scripts when the component is unmounted
        };

    }, [bookingDetails.distanceMatrix.distance.value, bookingDetails.distanceMatrix.changeFlag, bookingDetails.generalDetails.numberOfHours]);

    const selectVehicle = (category) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            bookingFee: category.bookingFee,
            selectedVehicleId: category.id,
            vehicleSelection: {
                id: category.id,
                type: category.type,
                name: category.title
            }
        }));

    }

    const handleImageError = (event) => {
        event.target.src = '/assets/img/vehicles/bcc/cars.png';
    };

    return (
        <div id="vehicleselect" className="row gx-md-2 gy-2 isotope mb-4">
            {fleetData.map((category) => (
                <React.Fragment key={category.id}>
                    <div className="project item col-12">
                        <div className="card mb-4 lift overflow-hidden">
                            <div className="card-body p-0">
                                <div className="row justify-content-between align-items-end m-0">
                                    <div className="col-md-4 col-12 p-3 pb-0">
                                        <img src={`${category.logo?.path}${category.logo?.name}`} className="img-flud" alt={"Vehicle"} onError={handleImageError} />
                                    </div>
                                    <div className="col-12 col-md-8 text-primary pe-0">
                                        <div className="row justify-content-between align-items-start m-0 gx-4">
                                            <div className="col-12 col-md-8 ps-0">
                                                <h3 className="fs-30 pe-3 mt-3 mb-0 text-primary">{category.title}
                                                </h3>
                                                <p className="mb-3 pe-3 fs-14 fw-normal">{category.subTitle}</p>
                                                <p className="mb-0 pe-3 fs-14 fw-normal text-muted">{category.shortDescription}</p>
                                            </div>
                                            <div className="col-12 col-md-4 ps-0 text-end">
                                                <div className="fs-36 fw-bolder pe-3 mt-3 mb-0 text-primary">${category.bookingFee}</div>
                                                <p className="mb-0 pe-3 fs-14 fw-normal text-muted">{category.disclaimer}</p>
                                            </div>
                                        </div>
                                        <div className="col-12 bg-primary p-0">
                                            <span
                                                className="d-flex justify-content-between align-items-center px-3 py-1">
                                                <span className="d-flex align-items-center text-white">
                                                    <i className="uil uil-users-alt text-secondary me-1"></i> {category.features?.passenger?.max ?? 0} <span
                                                        className="ms-2 d-none d-xl-inline">Passengers</span>
                                                </span>
                                                <span className="d-flex align-items-center text-white">
                                                    <i className="uil uil-briefcase-alt text-secondary me-1"></i> {category.features?.suitcases ?? 0} <span
                                                        className="ms-2 d-none d-xl-inline">Suitcases</span>
                                                </span>
                                                <span className="d-flex align-items-center text-white">
                                                    <i className="uil uil-shopping-bag text-secondary me-1"></i> {category.features?.smallBags ?? 0} <span
                                                        className="ms-2 d-none d-xl-inline">Small Bags</span>
                                                </span>
                                                <span className="text-white">
                                                    <div className="form-check p-0">
                                                        <input id={category.id} name="vehicleGroup" type="radio" 
                                                        className="carnumber form-check-input d-none" 
                                                        checked={bookingDetails.selectedVehicleId === category.id}
                                                        required />
                                                        <label className="form-check-label btn btn-sm btn-outline-secondary rounded" htmlFor={category.id} onClick={() => selectVehicle(category)}>Select Vehicle</label>
                                                    </div>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </React.Fragment>
            ))}
            {bookingErrors.selectedVehicleId && (
                                        <div class="text-danger"> {bookingErrors.selectedVehicleId}</div>
                                    )}
        </div>
        

    );
}

export default FleetRange;