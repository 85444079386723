import React from 'react';

import { Link } from 'react-router-dom';
import { BookAndManageButton } from '../common/genericComponent';


function HeroSitemap() {
  return (
    <section className="wrapper image-wrapper bg-image bg-overlay text-white" data-image-src="./assets/img/photos/b16.jpg">
      <div className="container py-12 position-relative">
        <div className="row gx-0 align-items-center">
          <div className="col-12 col-md-6 text-start" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="py-1 py-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/service-areas">Sitemap</Link></li>
                </ol>
              </nav>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSitemap;