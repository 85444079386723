import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import PrivacyPolicy from './privacy-policy';
import HeroPrivacyPolicy from './hero-privacy-policy';
import { Helmet } from "react-helmet-async";

function MainPrivacyPolicy() {
    return (
        <div className="content-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.businessclasschauffeur.com.au/bccPolicies" />
            </Helmet>
            <Menu />
            <HeroPrivacyPolicy />
            <PrivacyPolicy />
            <Footer />
        </div>
    );
}

export default MainPrivacyPolicy;