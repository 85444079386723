import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';



function Footer() {
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleClick = (store) => {
        setMessage(`The ${store} is currently under development and will be available soon!`);
        setShowModal(true); // Show the modal
    };

    const logoStyle = {
        width: 200,  // Use number for pixel value
        height: 100   // Use number for pixel value
    };

    const handleClose = () => setShowModal(false);

    return (
        <footer className="bg-primary text-inverse">
            <div className="container py-13 py-md-15">
                <div className="row gy-4 gy-lg-0">
                    <div className="col-md-12 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                        <div className="widget">
                            <a href="./">
                                <img src="./assets/img/bcc_logo_trans.png" srcSet="./assets/img/bcc_logo_trans.png 2x" alt="" style={logoStyle} />
                            </a>
                            <p className="mb-4">Business Class Chauffeur offers premium transportation services, providing a seamless and luxurious travel experience for individuals and businesses.</p>
                            <p className="mb-4">© 2024 Business Class Chauffeur. <br className="d-none d-lg-block" />All rights reserved.</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-9">
                        <div className="row gy-4 gy-lg-0 p-0 m-0">
                            <div className="col-md-6 col-lg-4 px-md-2 px-0 mb-md-0 mb-4" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <div className="widget">
                                    <h6 className="widget-title text-uppercase text-white mb-3">Get in Touch</h6>
                                    <address>{process.env.REACT_APP_BUSINESS_ADDRESS}</address>

                                    <p>
                                        <a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a>
                                    </p><p>

                                        <a href={`mailto:${process.env.REACT_APP_BUSINESS_SUPPORT_EMAIL}`} className="link-body">
                                            {process.env.REACT_APP_BUSINESS_SUPPORT_EMAIL}</a>
                                    </p>
                                </div>

                            </div>
                            <div className="col-md-6 col-lg-2 px-md-2 px-0 mb-md-0 mb-4" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <div className="widget">
                                    <h6 className="widget-title text-uppercase text-white mb-3">Support</h6>
                                    <ul className="list-unstyled  mb-0">
                                        <Link to="/about" className="dropdown-item"> About Us </Link>
                                        <Link to="/termsAndConditions" className="dropdown-item"> Terms of Use </Link>
                                        <Link to="/bccPolicies" className="dropdown-item"> Privacy Policy </Link>
                                        <Link to="/sitemap" className="dropdown-item">Sitemap </Link>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 px-md-2 px-0 mb-md-0 mb-4" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <div className="widget">
                                    <h6 className="widget-title text-uppercase text-white mb-3">SECURE PAYMENTS</h6>
                                    <img src="./assets/img/photos/payment.png" alt="" className="img-flud mb-5" />
                                    <h6 className="widget-title text-uppercase text-white mb-3">LET'S STAY CONNECTED</h6>
                                    <nav className="nav social social-white">
                                        <a href="https://www.facebook.com/share/4jdT2iFULoZ8cqw9/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><i className="uil uil-facebook-f"></i></a>
                                        <a href="https://www.instagram.com/businessclasschauffeur/profilecard/?igsh=MWt1Nzc4djF1NGp1Yg==" target="_blank" rel="noopener noreferrer"><i className="uil uil-instagram"></i></a>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 px-md-2 px-0 mb-md-0 mb-4" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <div className="widget">
                                    <h6 className="widget-title text-uppercase text-white mb-3">DOWNLOAD THE APP</h6>
                                    <div className="d-flex d-lg-block justify-content-start gap-1">
                                        <a href="#" className="col-6 col-lg-12 d-block m-0 mb-lg-3" onClick={() => handleClick('Google Play Store')}>
                                            <img src="./assets/img/photos/googleplay.png" className="bg-white h-100 w-100 rounded-3" alt="" />
                                        </a>
                                        <a href="#" className="col-6 col-lg-12 d-block" onClick={() => handleClick('App Store')}>
                                            <img src="./assets/img/photos/appstore.png" className="bg-white h-100 w-100 rounded-lg-4 rounded-2" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Modal component */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Work in Progress</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </footer>
    );
}

export default Footer;