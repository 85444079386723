import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { BookingContext } from "../../../contexts/bookingContext";
import { saveBookingDetails, updateBookingWithPaymentReference, updateBookingWithRejectedPaymentStatus } from "../../../db/bookingDAO";
import { updatePaymentIntentWithBookingId } from "../../../services/payment-service";
import { Link } from 'react-router-dom';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [bookingReferenceId, setBookingReferenceId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  let { bookingDetails, setBookingDetails } = useContext(BookingContext);
  const confirmationRef = useRef(null);
  const [scrollToConfirmation, setScrollToConfirmation] = useState(false);

  useEffect(() => {
    if (bookingDetails.bookingStatus === 'CONFIRMED') {
      setScrollToConfirmation(true);
    }
  }, [bookingDetails.bookingStatus]);

  useEffect(() => {
    if (scrollToConfirmation && confirmationRef.current) {
      confirmationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        window.scrollBy(0, -150); // Adjust as needed
      }, 300);
      setScrollToConfirmation(false);
    }
  }, [scrollToConfirmation]);

  const processPaymentViaStripe = async (e, bookingId) => {

    e.preventDefault();

    let paymentRefId = -1;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return paymentRefId;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: `${window.location.origin}/completion`,
      },
      redirect: 'if_required',
    });

    if (error) {
      // Handle error cases
      bookingDetails.paymentDetails.error.code = error.type;
      bookingDetails.paymentDetails.error.description = error.message;
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message); // not need alrady taken care by strip component
      } else if (error.type === 'invalid_request_error') {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred. Please try again!");
      }
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      // Payment was successful
      setMessage("Congratulations! Your Booking is successful. Booking RefId for your reference: " + bookingId);
      console.log("Payment successful!", paymentIntent);

      // Extract payment details
      const paymentId = paymentIntent.id;
      paymentRefId = paymentId;
      const amount = paymentIntent.amount;
      const currency = paymentIntent.currency;

      console.log("Payment ID:", paymentId);
      console.log("Amount:", amount);
      console.log("Currency:", currency);

      // Perform actions for successful payment, e.g., update database or UI
    } else {
      // Handle other statuses if necessary
      setMessage("Payment processing.");
    }

    setIsProcessing(false);
    return paymentRefId;
  };

  const saveBookingAndProcessPayment = async (e) => {
    e.preventDefault();

    /*
    let bookingId = -1;

    if (bookingDetails.bookingId != 0) {
      bookingId = bookingDetails.bookingId;
    } else {
      bookingId = await saveBookingDetails(bookingDetails);
    }

    if (bookingId === -1) {
      setMessage("Error in creating the booking, Please contact the support team.");
    } else {
      bookingDetails.bookingId = bookingId;
      setBookingDetails((prevDetails) => ({
        ...prevDetails,
        isBookingLogged: true
      }));

      await updatePaymentIntentWithBookingId(bookingDetails.paymentDetails.paymentIntentId, bookingId);
      let paymentId = await processPaymentViaStripe(e, bookingId);
      */
      


      //
      let bookingId = bookingDetails.bookingId;
      let paymentRefId = -1;
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return paymentRefId;
      }
  
      setIsProcessing(true);
  
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // return_url: `${window.location.origin}/completion`,
        },
        redirect: 'if_required',
      });
  
      if (error) {
        // Handle error cases
        bookingDetails.paymentDetails.error.code = error.type;
        bookingDetails.paymentDetails.error.description = error.message;
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message); // not need alrady taken care by strip component
        } else if (error.type === 'invalid_request_error') {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred. Please try again!");
        }
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        // Payment was successful
        setMessage("Congratulations! Your Booking is successful. Booking RefId for your reference: " + bookingId);
        console.log("Payment successful!", paymentIntent);
  
        // Extract payment details
        const paymentId = paymentIntent.id;
        paymentRefId = paymentId;
        const amount = paymentIntent.amount;
        const currency = paymentIntent.currency;
  
        console.log("Payment ID:", paymentId);
        console.log("Amount:", amount);
        console.log("Currency:", currency);
  
        // Perform actions for successful payment, e.g., update database or UI
      } else {
        // Handle other statuses if necessary
        setMessage("Payment processing.");
      }
  
      setIsProcessing(false);

      //

      let paymentId = paymentRefId;
      if (paymentId === -1) {
        bookingDetails.bookingStatus = 'REJECTED';
        bookingDetails.paymentDetails.paymentRefId = paymentId;
        bookingDetails.paymentDetails.amount = bookingDetails.bookingFee;
        updateBookingWithRejectedPaymentStatus(bookingId, bookingDetails.paymentDetails);
      } else {
        updateBookingWithPaymentReference(bookingDetails, bookingId, paymentId);
        bookingDetails.paymentDetails.paymentRefId = paymentId;
        setBookingDetails((prevDetails) => ({
          ...prevDetails,
          bookingStatus: 'CONFIRMED'
        }));

        setBookingReferenceId(bookingDetails.bookingReferenceId);
      }
    }
  


  return (
    <>
      {bookingDetails.bookingStatus === 'CONFIRMED' ? (

        <div className="container text-center mt-5" ref={confirmationRef} id="booking-confirmation-div">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card p-4">
                <div className="card-body">
                  <h1 className="card-title">Payment Successful!</h1>
                  <p className="card-text">Thank you for your payment. Your booking has been completed successfully. <br /> Booking reference Id:
                    <span className="fw-bold text-dark bg-warning px-2">{bookingReferenceId}</span></p>
                  <div className="mt-4">
                    <Link to="/" className="btn btn-primary">
                      Go to Home
                    </Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) :
        <div>
          <PaymentElement id="payment-element" />
          <br />
          <button disabled={isProcessing || !stripe || !elements} id="submit" className="form-check-label btn-sm btn btn-secondary" onClick={saveBookingAndProcessPayment}>
            <span id="button-text">
              {isProcessing ? "Processing ... " : "Pay now"}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </div>

      }
    </>
  );

}